.custom-calendar {
    border: 1px solid #000000;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    background-color: #ffffff;
    font-family: 'Arial', sans-serif;
}

/* Add styling for the color tiles in calendar */
.color-tile {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: -1;
    border-radius: 4px;
}

/* Ensure date numbers are visible over the color backgrounds */
.custom-calendar .react-calendar__tile {
    position: relative;
    z-index: 1;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: 1.2em; /* Larger numbers */
    padding: 10px; /* Increase padding for larger clickable area */
}

.custom-calendar .react-calendar__tile:enabled:hover {
    background-color: #e6f7ff;
}

/* Make the current date tile a perfect circle by ensuring equal dimensions */
.custom-calendar .react-calendar__tile--now {
    background-color: transparent; /* Remove default background */
    font-weight: bold;
    position: relative;
}

/* Create a perfect circle using a pseudo-element */
.custom-calendar .react-calendar__tile--now::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px; /* Fixed size for circle */
    height: 36px; /* Equal to width for perfect circle */
    background-color: #f80000;
    border-radius: 50%;
    z-index: -1;
}


.custom-calendar .react-calendar__tile--active {
    background-color: #4caf50;
    color: white;
    border-radius: 4px;
    font-weight: bold; /* Make the active date bold */
}

.custom-calendar .react-calendar__month-view__days__day--weekend {
    color: #d32f2f; /* Different color for weekends */
}

.custom-calendar .react-calendar__navigation button {
    color: #000000;
    min-width: 44px;
    background: none;
    font-size: 2em; /* Larger navigation buttons */
    margin-top: 8px;
}

.custom-calendar .react-calendar__navigation button:enabled:hover,
.custom-calendar .react-calendar__navigation button:enabled:focus {
    background-color: #e6f7ff;
}

.custom-calendar .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

.custom-calendar .react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: bold;
    color: #000000;
    font-size: 1.4em; /* Larger weekday names */
}

.custom-calendar .react-calendar__month-view__weekdays__weekday {
    padding: 5px 0;
}

.custom-calendar .react-calendar__month-view__days__day--neighboringMonth {
    color: #3c94b7;
}

/* Highlight class for dates with punch records */
.custom-calendar .highlight {
    border-radius: 20px;
    font-weight: semi-bold;
}