.react-calendar {
    border: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    font-family: 'Arial', sans-serif;
    background: #ffffff;
    padding: 20px;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.react-calendar__navigation button {
    color: #0800ee;
    background: none;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    transition: color 0.3s ease;
}

.react-calendar__navigation button:disabled {
    color: #d3d3d3;
}

.react-calendar__navigation button:hover:not(:disabled) {
    color: #388e3c;
}

.react-calendar__tile {
    padding: 15px;
    background: #f9f9f9;
    border-radius: 8px;
    transition: background 0.3s ease, transform 0.3s ease;
    text-align: center;
}

.react-calendar__tile--active {
    background: #4caf50;
    color: white;
    transform: scale(1.1);
}

.react-calendar__tile--now {
    background: #ffeb3b;
    font-weight: bold;
}

.react-calendar__tile:hover {
    background: #e0e0e0;
    transform: scale(1.05);
}

.react-calendar__month-view__days__day--weekend {
    color: #d32f2f;
}
.calendar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.calendar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.date-info {
    text-align: center;
    margin-top: 1rem;
}

.date-info p {
    margin: 0.5rem 0;
}