/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.help{
  color: teal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}





.boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.boxes div {
  padding: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.boxes h2 {
  margin-top: 20px;
}

.boxes a:hover {
  color: #777;
}

.form {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}





.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #000;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;

}

.btn-sm {
  padding: 5px 15px;
  font-size: 13px;
}


/* 3 column  */

.sitesColumn-number {
  margin-bottom: 30px;
}

.sitesColumn,
.sitesColumn-headings {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #f4f4f4;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
}









.ticket,
.ticket-headings {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 5px; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #f8f8f8cf;
  padding: 5px 10px;
  border-radius: 50px;
  text-align: center;
}






.status {
  background-color: #333;
  color: #fff;
  width: 100px;
  padding: 0 20px;
  justify-self: center;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
}

.status-true {
  background-color: rgb(57, 165, 57, 0.80);
  color: #fff;
  border-radius: 10px;
}

.status-open {
  
  width: auto;
  background-color: darkorange;
  color: #fff;
  border-radius: 10px;
}

.status-false {
  background-color: rgb(204, 6, 6, 0.80);
  color: #fff;
  border-radius: 10px;
}

.ticket-page {
  position: relative;
  text-align: left;
}

.ticket-page h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-page .btn {
  margin-bottom: 30px;
}

.ticket-page .btn-block {
  width: 100%;
  margin-top: 30px;
}

.ticket-desc {
  margin: 20px 0;
  font-size: 17px;
  background-color: #f4f4f4;
  border: 1px #ccc solid;
  padding: 10px 15px;
  border-radius: 5px;
}

.note {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}

.note-head {
  background: #f4f4f4;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.note-date {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 14px;
}

.delete-note {
  color: red;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.btn-close {
  background: none;
  border: none;
  color: #000;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
}

.btn-close:hover {
  color: red;
  transform: scale(0.98);
}

p.status-in-progress {
  color: orangered;
}

p.status-waiting {
  color: red;
}

p.status-ready {
  color: steelblue;
}

p.status-complete {
  color: green;
}

footer {
  position: sticky;
  top: 95vh;
  text-align: center;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .boxes {
    grid-template-columns: 1fr;
  }

  .form {
    width: 90%;
  }

  .ticket-created h2,
  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

/* Stripe  */

.payButton {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100% ;
  justify-content: center;
}

.payButton:hover {
  filter: contrast(115%);
}

.custom-underline {
  text-decoration: underline;
  text-decoration-color: black; /* Sets the underline color to black */
  text-decoration-thickness: 4px; /* Makes the underline thicker */
}

/* Hide the logout button on smaller screens */
@media (max-width: 768px) {
  .bars-icon + li {
      display: none;
  }
}

/* Responsive design for the bars icon */
.bars-icon {
  display: none;
}

@media (max-width: 768px) {
  .bars-icon {
      display: inline-block;
  }
}